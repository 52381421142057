// MapComponent.js

import React, { useEffect, useRef, useState, useCallback, forwardRef, useImperativeHandle } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import SearchComponent from './SearchComponent';


const useMapInitialization = (mapContainer) => {
  const mapRef = useRef(null);
  const [mapInitialized, setMapInitialized] = useState(false);

  useEffect(() => {
    if (mapRef.current || !mapContainer.current) return;

    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [17.0770, -22.5725],
      zoom: 12
    });

    map.on('load', () => {
      console.log('Map loaded successfully');
      setMapInitialized(true);
      map.resize();
    });

    const navControl = new mapboxgl.NavigationControl();
    map.addControl(navControl);

    navControl._container.style.position = 'absolute';
    navControl._container.style.top = '0';
    navControl._container.style.right = '0';

    mapRef.current = map;

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [mapContainer]);

  return { mapRef, mapInitialized };
};

const usePopupManagement = (mapRef) => {
  const popupRef = useRef(null);

  useEffect(() => {
    if (!mapRef.current) return;

    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    });

    popupRef.current = popup;

    return () => {
      if (popupRef.current) {
        popupRef.current.remove();
        popupRef.current = null;
      }
    };
  }, [mapRef]);

  const showPopup = useCallback((lngLat, content) => {
    if (popupRef.current && mapRef.current) {
      popupRef.current
        .setLngLat(lngLat)
        .setHTML(content)
        .addTo(mapRef.current);
    }
  }, [mapRef]);

  const hidePopup = useCallback(() => {
    if (popupRef.current) {
      popupRef.current.remove();
    }
  }, []);

  return { showPopup, hidePopup, popupRef };
};

const MapComponent = forwardRef(({ onAddTodo }, ref) => {
  const mapContainer = useRef(null);
  const { mapRef, mapInitialized } = useMapInitialization(mapContainer);
  const { showPopup, hidePopup } = usePopupManagement(mapRef);

  useImperativeHandle(ref, () => ({
    flyTo: (center) => {
      if (mapRef.current && Array.isArray(center) && center.length === 2) {
        const [lng, lat] = center;
        if (!isNaN(lng) && !isNaN(lat)) {
          mapRef.current.flyTo({ center: [lng, lat], zoom: 18 });
        }
      }
    }
  }));

  const handleMapClick = useCallback((e) => {
    hidePopup();

    if (!mapRef.current) return;

    const features = mapRef.current.queryRenderedFeatures(e.point, {
      layers: ['poi-label']
    });

    if (features.length > 0) {
      const poiName = features[0].properties.name;
      const lng = e.lngLat.lng;
      const lat = e.lngLat.lat;
      const formattedCoords = `${lng.toFixed(4)}, ${lat.toFixed(4)}`;

      const popupContent = `
        <div>
          <strong>POI:</strong> ${poiName}<br>
          <strong>Koordinaten:</strong> ${formattedCoords}<br>
          <button id="add-poi-button">Zur Liste hinzufügen</button>
        </div>
      `;

      showPopup(e.lngLat, popupContent);

      setTimeout(() => {
        const addButton = document.getElementById('add-poi-button');
        if (addButton) {
          addButton.addEventListener('click', () => {
            onAddTodo(poiName, lng, lat);
            hidePopup();
          });
        }
      }, 0);
    }
  }, [mapRef, showPopup, hidePopup, onAddTodo]);

  useEffect(() => {
    const map = mapRef.current;
    if (map && mapInitialized) {
      map.on('click', handleMapClick);
    }

    return () => {
      if (map) {
        map.off('click', handleMapClick);
      }
    };
  }, [mapRef, mapInitialized, handleMapClick]);

  return (
    <div style={{
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 440,
      overflow: 'hidden'
    }}>
      <div ref={mapContainer} style={{ width: '100%', height: '100%' }} />
      {mapInitialized && <SearchComponent mapRef={mapRef} />}
    </div>
  );

});

export default MapComponent;