import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import mapboxgl from 'mapbox-gl';

// Setzen Sie hier Ihren Mapbox-Zugriffstoken ein
mapboxgl.accessToken = 'pk.eyJ1IjoibmFtZm8iLCJhIjoiOVMyZGZ5QSJ9.MfibZjPoM9ArT89GNweUxg';

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Überprüfung des Tokens
console.log('Mapbox Token:', mapboxgl.accessToken);