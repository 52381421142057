// App.js

import React, { useState, useCallback, useRef } from 'react';
import MapComponent from './MapComponent';
import DraggableList from './DraggableList';
import './App.css';

function App() {
  const [todos, setTodos] = useState([]);
  const mapRef = useRef(null);

  const addTodo = useCallback((content, lng, lat) => {
    setTodos(prevTodos => [
      ...prevTodos,
      { 
        id: `item-${Date.now()}`, 
        content, 
        lng: parseFloat(lng), 
        lat: parseFloat(lat) 
      }
    ]);
  }, []);

  const removeTodo = useCallback((id) => {
    setTodos(prevTodos => prevTodos.filter(todo => todo.id !== id));
  }, []);

  const handleItemClick = useCallback((lng, lat) => {
    if (mapRef.current && !isNaN(lng) && !isNaN(lat)) {
      mapRef.current.flyTo([lng, lat]);
    }
  }, []);

  return (
    <div className="app-container">
      <MapComponent onAddTodo={addTodo} ref={mapRef} />
      <div className="list-container">
        <DraggableList 
          items={todos} 
          setItems={setTodos} 
          onItemClick={handleItemClick} 
          onItemRemove={removeTodo}
        />
      </div>
    </div>
  );
}

export default App;