// SearchComponent.js

import React, { useEffect, useRef } from 'react';

const SearchComponent = ({ mapRef }) => {
  const inputRef = useRef(null);
  const searchBoxRef = useRef(null);

  useEffect(() => {
    if (!window.google || !window.google.maps || !window.google.maps.places) {
      console.error('Google Maps API ist nicht verfügbar. Stelle sicher, dass sie korrekt geladen wurde.');
      return;
    }

    const mapInstance = mapRef.current;
    searchBoxRef.current = new window.google.maps.places.SearchBox(inputRef.current);

    const updateBounds = () => {
      if (searchBoxRef.current && mapInstance.getBounds) {
        const bounds = mapInstance.getBounds();
        searchBoxRef.current.setBounds({
          north: bounds.getNorth(),
          east: bounds.getEast(),
          south: bounds.getSouth(),
          west: bounds.getWest()
        });
      }
    };

    const handlePlacesChanged = () => {
      const places = searchBoxRef.current.getPlaces();
      if (places && places.length > 0) {
        const place = places[0];
        if (place.geometry && place.geometry.location) {
          const lng = place.geometry.location.lng();
          const lat = place.geometry.location.lat();
          
          mapInstance.flyTo({
            center: [lng, lat],
            zoom: 18
          });
        }
      }
    };

    mapInstance.on('moveend', updateBounds);
    searchBoxRef.current.addListener('places_changed', handlePlacesChanged);

    updateBounds();

    return () => {
      mapInstance.off('moveend', updateBounds);
      if (searchBoxRef.current) {
        window.google.maps.event.clearInstanceListeners(searchBoxRef.current);
      }
    };
  }, [mapRef]);

  return (
    <div className="search-container" style={{
      position: 'absolute',
      top: '10px',
      left: '10px',
      zIndex: 1,
      backgroundColor: 'white',
      padding: '5px',
      borderRadius: '4px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
    }}>
      <input
        ref={inputRef}
        type="text"
        placeholder="Suche nach Orten"
        style={{
          width: '250px',
          padding: '5px',
          fontSize: '16px',
          border: '1px solid #ccc',
          borderRadius: '4px'
        }}
      />
    </div>
  );
};

export default SearchComponent;