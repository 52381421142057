import React from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

const DraggableList = ({ items, setItems, onItemClick, onItemRemove }) => {
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    setItems(newItems);
  };

  return (
    <div className="draggable-list-container">
      <h2>Meine To-Do Liste</h2>
      
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {(provided) => (
            <ul {...provided.droppableProps} ref={provided.innerRef} className="draggable-list">
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`draggable-list-item ${snapshot.isDragging ? 'is-dragging' : ''}`}
                    >
                      <span onClick={() => {
                        if (typeof item.lng === 'number' && typeof item.lat === 'number') {
                          onItemClick(item.lng, item.lat);
                        }
                      }}>
                        {item.content}
                      </span>
                      <button 
                        onClick={(e) => {
                          e.stopPropagation();
                          onItemRemove(item.id);
                        }}
                        className="delete-button"
                      >
                        ×
                      </button>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default DraggableList;